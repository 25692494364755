import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const DeleteAccount: React.FC = () => {
  const navigate = useNavigate();
  const [requestInProgress, setRequestInProgress] = useState<boolean>(true);
  return (
    <div className="h-full w-full flex flex-col items-center p-8 gap-6">
      <p className="font-bold text-3xl">Delete Your Account</p>
      <p className="font-light text-xl">
        Use the form below to request deletion of your account.
      </p>
      <div className="cursor-pointer py-1 px-3 bg-blue-300 rounded-lg" onClick={() => navigate('/')}>Go Back Instead</div>
      {
        requestInProgress ? <p>Loading Form...</p> : null
      }   
      <iframe
        title="Delete Account Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSej8442wZpQ8OL9kqg0SGxARVVFpTKopRXswynKC_D_BXyUmg/viewform?embedded=true"
        width="640"
        height="900"
        onLoad={() => setRequestInProgress(false)}
      />
      </div>
  );
};
