import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PrivacyPolicy } from "./Privacy";
import { TermsOfUse } from "./Terms";
import { Credits } from "./Credits";
import { DeleteAccount } from "./DeleteAccount";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    element: <TermsOfUse />,
  },
  {
    path: "/credits",
    element: <Credits />,
  },
  {
    path: "/delete-account",
    element: <DeleteAccount />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
