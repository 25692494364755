import React from "react";
import Header from "./Header";
import Contact from "./Contact";
import Highlight from "./Highlight";
import Finisher from "./Finisher";
function App() {
  return (
    <div className="container mx-auto bg-white text-slate-900 h-full">
      <Header />
      <Highlight />
      <Finisher />
      <Contact />
    </div>
  );
}

export default App;
