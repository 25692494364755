import React from "react";
import { useNavigate } from "react-router-dom";

const Contact: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col p-4 m-4">
      <div className="flex flex-row items-center justify-center gap-6">
        <a href="/privacy" rel="noreferrer" className="text-xl">
          Privacy Policy
        </a>
        <a href="/terms" rel="noreferrer " className="text-xl">
          Terms &amp; Conditions
        </a>
        <div
          className="text-xl cursor-pointer"
          onClick={() => navigate("/credits")}
        >
          Credits
        </div>
        <div
          className="text-xl cursor-pointer"
          onClick={() => navigate("/delete-account")}
        >
          Delete Your Account
        </div>
      </div>
      <div className="flex flex-col items-center gap-4">
        <div className="text-xl ml-4 mt-2">
          © <a href={'https://teamblackbox.in/'} target={'_blank'} rel="noreferrer">TeamBlackBox Private Limited</a> 2024
        </div>
      </div>
    </div>
  );
};

export default Contact;
