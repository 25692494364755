import TBB from "./tbb.png";
import { ReactComponent as TakeASipLogo } from "./logo.svg";
import { useNavigate } from "react-router-dom";

export const Credits: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full w-full flex flex-col items-center p-8 gap-6">
      <p className="font-bold text-3xl">About Us</p>
      <p className="font-light text-xl">
        Take A Sip was built in the first ever 48 hour monthly hackathon at{" "}
        <a href="https://teamblackbox.in">Team Black Box</a> by our interns
        (Shruthi & Aditya) and us (the co-founders) on 26th & 27th November
        2022.
      </p>
      <div className="flex flex-row justify-center items-center gap-8">
        <div
          className="flex flex-row items-center justify-cente gap-2 p-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          onClick={() => window.open("https://teamblackbox.in")}
        >
          <img src={TBB} className="h-6 w-6" alt={"Team Black Box"} />
          <p className="font-bold text-xl">Team Black Box</p>
        </div>
        <div
          className="flex flex-row items-center justify-cente gap-2 p-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          onClick={() => navigate("/")}
        >
          <TakeASipLogo className="h-6 w-6" />
          <p className="font-bold text-xl">Take a Sip</p>
        </div>
      </div>
      <p className="font-bold text-3xl">Credits</p>
      <div className="flex flex-col items-center gap-2">
        <p className="font-bold text-xl">Developers</p>
        <p>Shruthi Abirami P (Intern #0 TBB 2022.2)</p>
        <p>Aditya Vardhan (Intern #1 TBB 2022.2)</p>
        <p>Kalp Adhwaryu (Intern #6 TBB 2023.1) [11 Jan, 2023]</p>
        <p>Suparv Lakhanpal (Intern #3 TBB 2023.1) [19 Feb, 2023]</p>
        <p>Siftee Ratra (Intern #4 TBB 2023.1) [4 March, 2023]</p>
        <p>Ekaksh Janweja (Intern #2 TBB 2023.1) [2 June, 2023]</p>
        <p>Yahaya Muhammad Bello (Intern #5 TBB 2023.1) [20 June, 2023]</p>
        <p>Aditya Shetty (Co-Founder TBB & Oak)</p>
        <p>Subramanian Elavathur (Co-Founder TBB & Oak)</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="font-bold text-xl">Quotes</p>
        <p>All quotes belong to the authors, discovered for Take a Sip by MT</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="font-bold text-xl">Icons</p>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/eat"
          title="eat icons"
        >
          Eat icons created by Flat Icons - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/hard-work"
          title="hard work icons"
        >
          Hard work icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/walking"
          title="walking icons"
        >
          Walking icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/exercise"
          title="exercise icons"
        >
          Exercise icons created by ultimatearm - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/water"
          title="water icons"
        >
          Water icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/document"
          title="document icons"
        >
          Document icons created by vectorsmarket15 - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/rocket"
          title="rocket icons"
        >
          Rocket icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/google"
          title="google icons"
        >
          Google icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/drink-water"
          title="drink water icons"
        >
          Drink water icons created by paulalee - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/let-it-go"
          title="let it go icons"
        >
          Let it go icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/reminder"
          title="reminder icons"
        >
          Reminder icons created by Darius Dan - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/water"
          title="water icons"
        >
          Water icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/glass-of-water"
          title="glass of water icons"
        >
          Glass of water icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/water-bottle"
          title="water bottle icons"
        >
          Water bottle icons created by smashingstocks - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/water-bottle"
          title="water bottle icons"
        >
          Water bottle icons created by Aranagraphics - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/notification"
          title="notification icons"
        >
          Notification icons created by Freepik - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/silence"
          title="silence icons"
        >
          Silence icons created by apien - Flaticon
        </a>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.flaticon.com/free-icons/close"
          title="close icons"
        >
          Close icons created by ariefstudio - Flaticon
        </a>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="font-bold text-xl">Artwork</p>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://www.freepik.com/free-vector/night-landscape-with-sea-mountains-horizon_20576211.htm"
        >
          Image by upklyak on Freepik
        </a>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="font-bold text-xl">Sounds</p>
        <a
          className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
          href="https://pixabay.com/sound-effects/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=music&amp;utm_content=6707"
        >
          Sound effects by Pixabay
        </a>
      </div>
    </div>
  );
};
