import React from "react";
import { ReactComponent as TakeASipLogo } from "./logo.svg";
import { ReactComponent as AppleBadge } from "./apple-badge.svg";

const Header: React.FC = () => {
  return (
    <div className="bg-white sticky top-0 py-3 px-2 flex flex-row items-center z-10">
      <div className="flex flex-row justify-center items-center gap-1">
        <TakeASipLogo className="w-16 h-16 lg:w-12 lg:h-12" />
        <div className="text-2xl xs:text-4xl ml-2">Take A Sip</div>
      </div>
      <div className="grow" />
      <a href="https://apps.apple.com/app/id6444727522">
        <AppleBadge className="h-16" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=app.takeasip">
        <img
          alt="Get it on Google Play"
          className="h-14"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    </div>
  );
};

export default Header;
